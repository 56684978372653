@import "styles/_theme-wellobe.scss";
.container {
  display: flex;
  flex-wrap: wrap;

  section.leftSection {
    margin: 2rem;
    width: calc(80% - 4rem);
  }

  section.rightSection {
    margin: 2rem;
    width: calc(20% - 4rem);
  }

  button {
    width: 30rem;
  }

  .selectAllSwitch {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 30rem;
    padding: 1rem;
    border-radius: 1rem;
  }
}

.rawSearchContainer {
  position: relative;
  margin-bottom: 2.4rem;
}

.clearButton {
  background-color: white;
  border-color: white;
  fill: black;

  &.clearButton {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    margin-top: -1rem;
    right: 1.2rem;

    &:hover {
      cursor: pointer;
      background-color: $color-core-grey-light-3;
    }
  }
}
