@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";
@import "~@styles/utils/mixins/breadcrumbs";

@mixin container-title {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }
}

.underline {
  @include underline;
}

.container {
  min-height: 100vh;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  text-align: center;
  padding: $spacing-twentyfour $spacing-sixteen;
  background-image: url("./assets/#{$product}/background.avif");
  background-size: cover;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding: $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixtyfour;
  }
}

.header {
  @include responsive-title-1;

  color: $color-web-text-headline;
}

.header-description {
  @include text-18-regular;

  @include breakpoint-medium-up {
    @include text-20-regular;

    max-width: 72rem;
    margin: 0 auto;
    padding: 0 $spacing-forty;
  }

  @include breakpoint-large-up {
    max-width: 80rem;
    padding: 0;
  }
}

.articles-container {
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 $spacing-sixteen $spacing-fortyeight;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix $spacing-eighty;
  }
}

.articles-groups {
  display: flex;
  flex-direction: column;
  gap: $spacing-fortyeight;

  @include breakpoint-medium-up {
    gap: $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-eighty;
  }
}

.breadcrumbs {
  @include breadcrumbs;
}

.more-articles-button-container {
  display: flex;
  justify-content: center;
  margin-top: $spacing-forty;
  width: 100%;

  @include breakpoint-medium-up {
    margin-top: $spacing-thirtytwo;
  }
}

.more-articles-button {
  margin: 0;
}

.footer-container {
  background-color: $color-web-background-section;
}

.footer-content-container {
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-twentyfour;
  padding: $spacing-fortyeight $spacing-sixteen;
  text-align: start;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
    flex-direction: row;
  }

  @include breakpoint-large-up {
    padding: $spacing-eighty $spacing-fiftysix;
    gap: $spacing-thirtytwo;
  }
}

.footer-headline {
  @include responsive-title-4;
}

.footer-description-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
  }
}

.footer-paragraph {
  @include text-16-regular;
}

.footer-header-container {
  @include container-title;

  @include breakpoint-medium-up {
    min-width: 30%;
  }
}

.latest-articles-title-container {
  @include container-title;
}

.latest-articles-title {
  @include responsive-title-4;

  margin-bottom: $spacing-sixteen;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-thirtytwo;
  }
}

.latest-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    gap: $spacing-fortyeight;
  }

  @include breakpoint-large-up {
    gap: $spacing-sixtyfour;
  }
}

.more-articles-title-container {
  @include container-title;
}

.more-articles-title {
  @include responsive-title-4;

  margin-bottom: $spacing-sixteen;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-thirtytwo;
  }
}

.medium-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: space-between;
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;
  }
}

.small-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;
  padding: 0;
  list-style-type: none;
  margin: 0;

  @include breakpoint-medium-up {
    flex-direction: row;
    justify-content: space-between;
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;
  }
}

.rest-of-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;
  padding: 0;
  list-style-type: none;
  margin: 0;

  @include breakpoint-medium-up {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-twentyfour;

    & > article {
      margin-bottom: $spacing-eight;
    }
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;

    & > article {
      margin-bottom: $spacing-thirtytwo;
    }
  }
}

.testimonials-container {
  margin-bottom: $spacing-fortyeight;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    margin-bottom: $spacing-eighty;
  }
}

.articles-list-item {
  width: 100%;
}
