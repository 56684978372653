@import "styles/_theme-wellobe.scss";
.container {
  display: flex;
  flex-direction: column;
  padding: 3.6rem;
}

.textarea {
  height: 70vh;
  padding: 2rem;
  border-radius: 1.5rem;
}

.button {
  width: 36rem;
  margin-top: 2.4rem;
  align-self: flex-end;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
