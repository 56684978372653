// scale factor from zeplin values
$scale-factor: 0.13rem;

$spacing-tiny: 4 * $scale-factor;
$spacing-small: 8 * $scale-factor;
$spacing-medium: 12 * $scale-factor;
$spacing-large: 16 * $scale-factor;

$spacing-four: 0.4rem;
$spacing-eight: 0.8rem;
$spacing-twelve: 1.2rem;
$spacing-sixteen: 1.6rem;
$spacing-twentyfour: 2.4rem;
$spacing-thirtytwo: 3.2rem;
$spacing-forty: 4rem;
$spacing-fortyeight: 4.8rem;
$spacing-fiftysix: 5.6rem;
$spacing-sixtyfour: 6.4rem;
$spacing-seventytwo: 7.2rem;
$spacing-eighty: 8rem;

$desktop-max-width: 116.8rem;
