@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";

.link {
  display: block;
  width: 100%;
  color: $color-text-primary;

  &:hover,
  &:active {
    text-decoration: none;
    color: $color-text-primary;
  }
}

.teaser-container {
  &--featured {
    @include breakpoint-large-up {
      display: flex;
      gap: $spacing-thirtytwo;
    }
  }

  &--small {
    @include breakpoint-medium-up {
      max-width: 22.8rem;
    }

    @include breakpoint-large-up {
      max-width: 36.8rem;
    }
  }

  &--medium {
    @include breakpoint-medium-up {
      max-width: 34.8rem;
    }

    @include breakpoint-large-up {
      max-width: 56.8rem;
    }
  }

  &--thumb {
    gap: $spacing-sixteen;
    display: flex;

    @include breakpoint-medium-up {
      gap: $spacing-twentyfour;
    }
  }
}

.image-container {
  &--featured {
    @include breakpoint-large-up {
      width: calc(65% - #{$spacing-thirtytwo});
    }
  }

  &--thumb {
    width: 30%;

    @include breakpoint-medium-up {
      width: 23%;
    }

    @include breakpoint-large-up {
      width: 100%;
      max-width: 16.8rem;
    }
  }
}

.image {
  margin: 0;
  padding-top: calc(100% * (3 / 4));
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  @include breakpoint-medium-up {
    border-radius: 3rem;
    max-width: 100%;
    width: auto;
  }

  &--small {
    border-radius: 2rem;

    @include breakpoint-large-up {
      border-radius: 3rem;
    }
  }

  &--large {
    padding-top: calc(100% * (9 / 16));
  }

  &--thumb {
    padding-top: 100%;
    border-radius: 2rem;

    @include breakpoint-medium-up {
      padding-top: calc(100% * (3 / 4));
    }
  }

  &--featured {
    @include breakpoint-medium-up {
      padding-top: calc(100% * (9 / 16));
    }

    @include breakpoint-large-up {
      border-radius: 4rem;
      padding-top: calc(100% * (3 / 4));
    }
  }

  picture {
    position: absolute;
    inset: 0;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    padding: 0;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $spacing-eight;
  margin-top: $spacing-sixteen;

  &--thumb {
    width: 70%;
    margin-top: 0;

    @include breakpoint-medium-up {
      width: 77%;
    }
  }

  &--featured {
    @include breakpoint-medium-up {
      gap: $spacing-eight;
      margin-top: 2.4rem;
    }

    @include breakpoint-large-up {
      gap: $spacing-sixteen;
      margin: 0;
      width: 35%;
    }
  }
}

.title {
  @include title-20;

  .link:hover & {
    @include breakpoint-medium-up {
      text-decoration: underline;
    }
  }

  &--large {
    @include responsive-title-5;
  }

  &--featured {
    @include responsive-title-3;

    overflow: visible;
    display: block;
    height: auto;
  }

  &--small {
    @include responsive-title-6;
  }

  &--thumb {
    @include responsive-title-6;
  }
}

.subtitle {
  @include text-16-regular;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;

  .link:hover & {
    @include breakpoint-medium-up {
      text-decoration: underline;
    }
  }

  &--featured {
    overflow: visible;
    display: block;

    @include breakpoint-medium-up {
      @include text-20-regular;
    }
  }

  &--thumb {
    display: none;

    @include breakpoint-small-up {
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }

    @include breakpoint-medium-up {
      -webkit-line-clamp: 2;
    }

    @include breakpoint-large-up {
      display: none;
    }

    @include breakpoint-larger-up {
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }
  }
}

.featured-tag {
  @include text-14-semi-bold-caps;

  border-radius: 0.6rem;
  background: $color-tag-label-background;
  padding: $spacing-four $spacing-eight;
  color: $color-tag-label-content;
}
