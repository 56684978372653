@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/variables/transitions";

.input {
  display: inline-block;
  -webkit-appearance: none;
  border: 0.1rem solid $color-text-field-border-dark;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  line-height: 4.2rem;
  padding: 0 1.2rem;
  height: 4.2rem;
  transition: border-color $transition-duration-fast ease-out;
  min-width: 0; // Fixes FF issue https://github.com/philipwalton/flexbugs/issues/152

  &:focus {
    outline: none;
    border-color: $color-text-field-border-focused;
  }

  &::-ms-clear {
    display: none;
  }

  &__expand {
    width: 100%;
  }

  &__error,
  &__error:focus {
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}
