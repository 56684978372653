@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/mixins/modals";

.content {
  background-color: white;
  width: 37.5rem;
  outline: none;
  transform: scale(0.75);
  transition: all $transition-duration-faster ease-in;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.16);
  margin: 13rem auto;

  @include breakpoint-small-down {
    width: 100%;
    margin: 0;
    min-height: 100vh;
  }
}

.content-after-open {
  transform: scale(1);
}

.content-before-close {
  transform: scale(0.75);
}

.overlay {
  @include overlay;
}

.overlay-after-open {
  @include overlayAfterOpen;
}

.overlay-before-close {
  @include overlayBeforeClose;
}
