@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/buttons";

@mixin small {
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.4rem 1.6rem;
}

@mixin medium {
  font-size: 1.5rem;
  line-height: 2.2rem;
  padding: 0.8rem 3rem;
}

@mixin large {
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1rem 4rem;
}

@mixin smallIcon {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
}

@mixin mediumIcon {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.8rem;
}

@mixin largeIcon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
}

.container {
  @include font-semi-bold;
  @include button-reset;

  display: inline-block;
  transition:
    background-color $transition-duration-fastest ease-out,
    border-color $transition-duration-fastest ease-out,
    color $transition-duration-fastest ease-out;
  margin: 0 0 2rem;
  border-radius: 1.2rem;
  min-width: 0;
  max-width: 100%;
  cursor: default;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &__small {
    @include small;
  }

  &__medium {
    @include medium;
  }

  &__large {
    @include large;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__expand {
    width: 100%;
  }

  @include breakpoint-small-down {
    &__ss {
      @include small;
    }

    &__sm {
      @include medium;
    }

    &__sl {
      @include large;
    }
  }

  @include breakpoint-medium-only {
    &__ms {
      @include small;
    }

    &__mm {
      @include medium;
    }

    &__ml {
      @include large;
    }
  }

  @include breakpoint-large-up {
    &__ls {
      @include small;
    }

    &__lm {
      @include medium;
    }

    &__ll {
      @include large;
    }
  }
}

.container__disabled.container__disabled {
  @include button-disabled;

  border-color: $color-button-primary-background-disabled;
  color: $color-button-primary-contents-disabled;
  background-color: $color-button-primary-background-disabled;
}

.inner {
  @include truncate;

  display: block;
  width: 100%;
  text-align: center;
}

.icon {
  display: inline-flex;
  flex-shrink: 0;

  @include breakpoint-small-down {
    &__ss {
      @include smallIcon;
    }

    &__sm {
      @include mediumIcon;
    }

    &__sl {
      @include largeIcon;
    }
  }

  @include breakpoint-medium-only {
    &__ms {
      @include smallIcon;
    }

    &__mm {
      @include mediumIcon;
    }

    &__ml {
      @include largeIcon;
    }
  }

  @include breakpoint-large-up {
    &__ls {
      @include smallIcon;
    }

    &__lm {
      @include mediumIcon;
    }

    &__ll {
      @include largeIcon;
    }
  }
}
