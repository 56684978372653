@import "~normalize.css/normalize.css";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/typography";
@import "~@styles/utils/mixins/breakpoints";
@charset "UTF-8";

* {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;

  @include breakpoint-medium-down {
    background-color: $color-web-background-secondary;
  }
}

:global {
  body {
    @include font-regular;

    display: block;
    flex-direction: column;
    font-family: $font-app-primary;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $color-text-primary;
    background-color: white;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    height: auto;
    text-rendering: optimizeLegibility;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    touch-action: manipulation;
    text-size-adjust: none;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.lock {
      overflow: hidden;
    }
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

p {
  @include p;
}

a {
  @include a;
}

b,
strong {
  @include font-semi-bold;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-app-primary;
  font-size: 1.4rem;

  /* TODO: Handle Bootstrap legacy line-height. Should preferrably be an even number.
     Need to go through affeted elements and potentially adjust layout. */
  line-height: 1.42857143;
}

/* Lazyload global setting */
:global .lazyloaded {
  opacity: 1 !important;
}
