@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

.foodstuffForm {
  display: flex;
  flex-flow: row no-wrap;
  flex-direction: column;

  @include breakpoint-large-up {
    flex-direction: row;
  }

  p {
    margin: 0.8rem auto;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 0.6rem;
  }
}

.foodstuffForm > div {
  display: flex;
  flex-flow: column wrap;
}

.mainProperties {
  width: 100%;

  @include breakpoint-large-up {
    width: calc(100% - 33.8rem);
  }
}

.nutrientProperties {
  width: 100%;

  @include breakpoint-large-up {
    width: 33.8rem;
  }
}

.additionalProperties {
  width: 40%;
}

.propertiesGroup {
  margin: 0.4rem;
  background-color: white;
  border-radius: 1rem;
  flex: 1;

  @include breakpoint-medium-up {
    margin: 1.2rem;
  }
}
