@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.container {
  background-color: white;
  text-align: center;
  border-radius: 1rem;
  padding: 3.2rem 6.4rem;
  margin-bottom: 2rem;
}

.paragraph {
  color: $color-text-primary;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0;
}

.disclaimer {
  @include text-12;

  margin-top: $spacing-eight;
  display: block;
}
