@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";

.container {
  display: inline-block;

  &__expand {
    display: block;
  }
}

.label {
  @include font-semi-bold;

  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-bottom: 0.5rem;
}

.error {
  font-size: 1.6rem;
  line-height: 1.5;
  color: $color-text-error;
  margin-top: $spacing-eight;
  width: 100%;
}
