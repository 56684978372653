@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/variables/transitions";

.textarea {
  display: inline-block;
  -webkit-appearance: none;
  border: 0.1rem solid $color-grey-medium;
  border-radius: 0.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.3rem 1.2rem;
  min-height: 19.2rem;
  transition: border-color $transition-duration-fast ease-out;
  resize: none;

  &:focus {
    outline: none;
    border-color: $color-grey-dark;
  }

  &::-ms-clear {
    display: none;
  }

  &__expand {
    width: 100%;
  }

  &__error,
  &__error:focus {
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  }
}
