@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";

@mixin breadcrumbs {
  margin: $spacing-twentyfour 0;

  @include breakpoint-medium-up {
    margin: $spacing-thirtytwo 0;
  }

  @include breakpoint-large-up {
    margin: $spacing-forty 0;
  }
}
