@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";

.container {
  width: 100%;
  padding: 0 $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    padding: 0;
    margin: 0 auto;
  }
}

.footnote {
  @include text-16-regular;

  border-top: 0.1rem solid $color-web-divider-secondary;
  padding: $spacing-sixteen 0 $spacing-forty;
  text-align: center;

  @include breakpoint-medium-up {
    padding: $spacing-twentyfour 0 $spacing-eighty;
  }
}
