@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/typography";

@mixin search-list-row {
  span {
    padding: 0.5rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-grow: 1;
    width: 2rem;
    overflow: hidden;
  }

  span.gtins {
    width: 5rem;
    flex-grow: 2;
  }

  span.select {
    width: 2rem;
    padding-right: 3rem;
  }

  span.name {
    width: 10rem;
    flex-grow: 2;
  }

  span.unitName {
    width: 10rem;
    flex-grow: 2;

    .defaultUnit {
      @include font-semi-bold;

      display: inline;
      padding: 0;
    }
  }

  span.brandName {
    width: 5rem;
    flex-grow: 2;
  }

  span.version {
    width: 3rem;
    flex-grow: 2;
  }

  span.visibilityStatus,
  span.mainCategory {
    width: 2rem;
    flex-grow: 2;
  }

  span.actions {
    width: 2rem;
    flex-grow: 2;
    flex-wrap: nowrap;

    div {
      flex-wrap: nowrap;
    }
  }

  span.defaultUnit,
  span.defaultUnit > span {
    min-width: 6rem;
    display: block;
    flex-grow: 2.5;
  }

  span.gramsPerMl,
  span.kcalPer100g {
    flex-wrap: nowrap;
    flex-grow: 2;
  }
}

.container {
  div:nth-child(2n) {
    background-color: $color-core-grey-light-3;
  }
}
