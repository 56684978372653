@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";

.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 2rem;
  text-align: center;

  @include breakpoint-medium-up {
    padding: 4rem 5rem;
  }

  @include breakpoint-large-up {
    width: 92%;
    max-width: 110rem;
  }

  &--redesign {
    padding: $spacing-fortyeight $spacing-sixteen $spacing-four;

    @include breakpoint-medium-up {
      padding-top: $spacing-sixtyfour;
    }

    @include breakpoint-large-up {
      padding-top: $spacing-eighty;
    }
  }

  &--service {
    @include breakpoint-large-up {
      padding-top: 3.6rem;
    }
  }
}

.title {
  @include breakpoint-large-up {
    padding-top: 1rem;
  }

  &--redesign {
    @include responsive-title-4;

    margin-bottom: $spacing-sixteen;

    &::before {
      @include underline;

      content: "";
      display: block;
      margin: 0 auto $spacing-eight;

      @include breakpoint-medium-up {
        margin-bottom: $spacing-sixteen;
      }

      @include breakpoint-large-up {
        margin-bottom: $spacing-twentyfour;
      }
    }

    @include breakpoint-medium-up {
      margin-bottom: $spacing-thirtytwo;
    }

    @include breakpoint-large-up {
      padding-top: 0;
    }
  }
}

.description {
  line-height: 1.63;
  margin: 0 0 2.2rem;

  &--redesign {
    max-width: 60rem;
    margin: 0 auto $spacing-twentyfour;

    @include breakpoint-medium-up {
      font-size: 2rem;
      line-height: 1.5;
      max-width: 75rem;
      margin-bottom: $spacing-thirtytwo;
    }
  }
}

.list {
  padding: 0;
  margin: 0;

  @include breakpoint-medium-up {
    display: flex;
  }
}

.item {
  list-style: none;
  margin-bottom: 4rem;

  @include breakpoint-medium-up {
    width: calc(100% / 3);
    margin-bottom: 0;
  }

  &--redesign {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing-sixteen;

    &:last-child {
      margin-bottom: 0.4rem;
    }

    @include breakpoint-medium-up {
      align-items: baseline;
      height: 100%;
    }
  }
}

.byline {
  max-width: 20rem;
  margin: $spacing-sixteen auto 0;

  &--redesign {
    max-width: unset;
    margin: 0;
    text-align: center;

    @include breakpoint-medium-up {
      max-width: 32rem;
      margin: $spacing-sixteen auto 0;
    }
  }
}

.icon {
  fill: $color-core-primary;
}

.icon-redesign {
  width: 3.2rem;
  height: 3.2rem;
  fill: none;
  stroke: $color-web-illustration-content;

  @include breakpoint-medium-up {
    width: 6.4rem;
    height: 6.4rem;
  }

  &--activity {
    fill: $color-web-illustration-content;
    stroke: none;
  }
}

.iconWrapper {
  display: flex;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 6.4rem;
  background-color: $color-web-illustration-background;
  align-items: center;
  justify-content: center;
  margin: $spacing-sixteen 0 $spacing-eight;

  @include breakpoint-medium-up {
    width: 11.2rem;
    height: 11.2rem;
    border-radius: 11.2rem;
    margin: 0 auto;
    margin-top: 2.2rem;
  }

  &:first-child {
    margin-top: $spacing-eight;

    @include breakpoint-medium-up {
      margin-top: 1rem;
    }
  }
}

.mobileIconContainer {
  width: 2rem;
  margin-right: 1rem;
}

.mobileIcon {
  height: 1.4rem;
  top: 0.1rem;
  position: relative;
  fill: #009780; // TODO: add new colors from figma

  @include breakpoint-medium-up {
    display: none;
  }
}

.buttonContainer {
  margin-top: $spacing-twentyfour;
}

.button {
  margin-bottom: 0;
}
