@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/variables/z-index";

@mixin overlay {
  position: fixed;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: $z-index-fourth;
  transition: opacity $transition-duration-fast ease-out;

  @media print {
    position: static; // IE11 and Firefox break page prematurely with position: fixed...
  }
}

@mixin overlayAfterOpen {
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media print {
    overflow: visible;
  }
}

@mixin overlayBeforeClose {
  opacity: 0;
}

@mixin overlayToaster {
  position: fixed;
  z-index: $z-index-fifth;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity $transition-duration-fast ease-out;
}

@mixin overlayTasterAfterOpen {
  opacity: 1;
}

@mixin overlayToasterBeforeClose {
  opacity: 0;
}
