@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

.health-benefit {
  background-image: url("./heart-check-icon.png");
  background-repeat: no-repeat;
  background-size: 50px 45px;
  background-position: center 2rem;
  padding: 8.5rem 2.5rem 2.8rem;
  font-size: 2.1rem;
  line-height: 2.9rem;
  text-align: center;
  max-width: 420px;
  border-radius: 1.5rem;
  border: 0.1rem solid $color-grey-light;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.1);
  margin: 4.5rem auto 0;
  color: $color-text-primary;

  @include breakpoint-medium-up {
    padding: 2.3rem 2.5rem 2.3rem 10rem;
    background-position: 2.5rem 2.9rem;
    text-align: left;
  }
}
