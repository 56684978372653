@media print {
  *,
  *::after,
  *::before {
    color: black !important;

    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  html,
  body {
    display: block;
    min-height: inherit;
    width: auto;
    height: auto;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  :global #navbar-top-header,
  :global #closed-pages-footer {
    display: none;
  }

  :global .print-hide {
    display: none !important;
  }
}
