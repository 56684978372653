@import "styles/_theme-wellobe.scss";
.container {
  position: relative;
  margin-bottom: 1rem;
  display: inline-block;

  &__expand {
    display: block;
  }
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.6rem;
  line-height: 4.2rem;
  border: 0.1rem solid $color-grey-medium;
  border-radius: 0.2rem;
  padding: 0 4rem 0 1rem;
  height: 4.2rem;
  background-color: white;
  color: black;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  &__expand {
    width: 100%;
  }
}

.caret-down-icon {
  position: absolute;
  width: 1.5rem;
  height: 0.9rem;
  top: 50%;
  margin-top: -0.3rem;
  right: 1.5rem;
  fill: $color-grey-medium;
  pointer-events: none;
}
