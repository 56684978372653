@import "styles/_theme-wellobe.scss";
.container {
  $this: &;

  background-color: $color-button-primary-background-default;
  border: 0.2rem solid $color-button-primary-background-default;
  color: $color-button-primary-contents-default;

  &:hover {
    background-color: $color-button-primary-background-hovered;
    border-color: $color-button-primary-background-hovered;
  }

  &__active {
    background-color: $color-button-primary-background-pressed;
    border-color: $color-button-primary-background-pressed;
  }

  &__outline {
    background-color: transparent;
    border-color: $color-button-primary-outline-border-default;
    color: $color-button-primary-outline-contents-default;

    &:hover {
      background-color: $color-button-primary-outline-background-hovered;
      border-color: $color-button-primary-outline-border-hovered;
      color: $color-button-primary-outline-contents-hovered;
    }

    &#{$this}__disabled {
      background-color: transparent;
      border-color: $color-button-primary-outline-border-disabled;
      color: $color-button-primary-outline-contents-disabled;
    }
  }

  &__outlineActive {
    background-color: $color-button-primary-outline-background-pressed;
    border-color: $color-button-primary-outline-border-pressed;
    color: $color-button-primary-outline-contents-pressed;
  }
}
