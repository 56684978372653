@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/accessibility";
@import "~@styles/utils/variables/transitions";

.input {
  @include visually-hidden;
}

.label {
  display: block;
  position: relative;
  height: 3rem;
  padding-right: 6.2rem;
  font-size: 1.6rem;
  line-height: 3rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: "";
    display: block;
    transition: all $transition-duration-faster;
    width: 4.8rem;
    height: 3rem;
    border-radius: 3rem;
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    display: block;
    background: $color-web-switch-track-default;
    position: absolute;
    top: 0.1rem;
    right: 1.9rem;
    transition: all $transition-duration-faster;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 2.8rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  }

  &__checked {
    &::before {
      background: $color-web-switch-track-checked;
      box-shadow: none;
    }

    &::after {
      transform: translateX(1.8rem);
    }
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__active {
    &::before {
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.1);
    }
  }

  &__small {
    height: 2rem;
    padding-right: 4.2rem;
    font-size: 1.4rem;
    line-height: 2rem;

    &::before {
      width: 3.2rem;
      height: 2rem;
      border-radius: 2rem;
    }

    &::after {
      content: "";
      display: block;
      background: $color-web-switch-track-default;
      position: absolute;
      top: 0.1rem;
      right: 1.3rem;
      transition: all $transition-duration-faster;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 1.8rem;
      box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
    }
  }
}

.label__checked.label__small {
  &::after {
    transform: translateX(1.2rem);
  }
}
