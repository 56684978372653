@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/transitions";

.container {
  background-color: $color-web-background-secondary;
  overflow: scroll;
  padding: 1.6rem 1.6rem 2.5rem;
  min-height: 10rem;

  @include breakpoint-medium-up {
    padding: 2.4rem;
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: 0.5rem;
  }

  h5 {
    margin: 0;
  }

  strong {
    @include font-semi-bold;

    font-size: 1.8rem;
  }

  img {
    display: block;
    max-width: 100%;
    border-radius: 2rem;
    overflow: hidden;
  }

  figure {
    margin: 0;
    position: relative;
    margin-bottom: 2.4rem;
  }

  figcaption {
    position: absolute;
    right: 1.6rem;
    bottom: 1.6rem;
    padding: 0.7rem;
    background-color: $color-web-background-default;
    border-radius: 1rem;
  }

  ul {
    padding-left: 3.5rem;
  }

  footer {
    color: $color-text-secondary;
    text-align: center;
    position: relative;
    margin-top: 3.2rem;
    padding-top: 1.6rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 4.4rem;
      height: 0.2rem;
      background-color: $color-text-primary;
    }

    img {
      width: 12rem;
      border-radius: 0;
      margin: 0 auto;
    }

    p {
      max-width: 30rem;
      font-size: 1.4rem;
      margin: 0 auto 1.6rem;
    }
  }
}

.footer-container {
  @include font-medium;

  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  position: relative;

  @include breakpoint-medium-up {
    padding: 1.6rem 3.2rem 3.2rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1.6rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 5.9rem;
    background: linear-gradient(
      180deg,
      rgba(244, 244, 244, 0) -50%,
      $color-web-background-secondary 92.5%
    );
    opacity: 0;
    transition: opacity $transition-duration-fastest ease-out;
    pointer-events: none;
  }

  &__top-shadow {
    &::before {
      opacity: 1;
    }
  }

  p {
    font-size: 1.4rem;
    flex-basis: 100%;

    @include breakpoint-medium-up {
      font-size: 1.6rem;
    }
  }
}

.button-decline {
  order: 1;
  margin: 0;

  @include breakpoint-medium-up {
    flex-grow: 1;
    order: 0;
  }
}

.button-accept {
  margin: 0 0 0.8rem;

  @include breakpoint-medium-up {
    margin: 0;
    flex-grow: 1;
  }
}

.button-read-more {
  margin: 0 auto;

  .icon {
    padding-top: 0.2rem;
    width: 2rem;
    height: 2rem;

    path,
    line {
      stroke: currentColor;
      stroke-width: 0.15rem;
    }
  }
}
