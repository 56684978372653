@import "styles/_theme-wellobe.scss";
.container {
  background-color: $color-button-secondary-background-default;
  border: 0.2rem solid $color-button-secondary-background-default;
  color: $color-button-secondary-contents-default;

  &:hover {
    background-color: $color-button-secondary-background-hovered;
    border-color: $color-button-secondary-background-hovered;
    color: $color-button-secondary-contents-hovered;
  }

  &__active {
    background-color: $color-button-secondary-background-pressed;
    border-color: $color-button-secondary-background-pressed;
    color: $color-button-secondary-contents-pressed;
  }

  &__outline {
    background-color: transparent;
    border-color: $color-button-secondary-outline-border-default;
    color: $color-button-secondary-outline-contents-default;

    &:hover {
      background-color: $color-button-secondary-outline-background-hovered;
      border-color: $color-button-secondary-outline-border-hovered;
      color: $color-button-secondary-outline-contents-hovered;
    }
  }
}
