@import "~@styles/utils/mixins/breakpoints";

@mixin underline {
  width: 4.4rem;
  height: 0.2rem;
  background: $color-text-primary;

  @include breakpoint-medium-up {
    width: 6.4rem;
    height: 0.3rem;
  }
}
