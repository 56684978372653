@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/breakpoints";

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin dl {
  @include list-reset;

  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-flow: wrap;
}

@mixin dt {
  @include font-semi-bold();

  font-size: 1.8rem;
  line-height: 2.4rem;
  width: 50%;
  padding: 0.6rem 0.5rem;

  @include breakpoint-small-down {
    font-size: 1.4rem;
    line-height: 2.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@mixin dd {
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 50%;
  margin: 0;
  padding: 0.6rem 0.5rem;

  @include breakpoint-small-down {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
