@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";

.container {
  color: $color-text-primary;
}

.wrapper {
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 2.5rem;
  border-top: 0.1rem solid transparent;

  @include breakpoint-large-up {
    max-width: $desktop-max-width;
  }

  &--redesign {
    max-width: unset;
    padding-bottom: 0;
  }
}

.offer-container {
  background-image: url("../../../components/ArticlesList/assets/#{$product}/background.avif");
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;

  &--highlighted {
    background-image: url("./background.avif");
  }
}

.title-container-redesign {
  max-width: $desktop-max-width;
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  padding: $spacing-twentyfour $spacing-sixteen;
  margin: 0 auto;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding-top: $spacing-sixtyfour;
  }
}

.title {
  @include font-bold();

  line-height: 4.2rem;
  text-align: center;
  margin-top: 2.7rem;
  margin-bottom: 1.5rem;
  padding: 0 2rem;

  @include breakpoint-medium-up {
    font-size: 4.6rem;
    line-height: 6.4rem;
  }

  &--redesign {
    @include responsive-title-1;

    margin: 0;
    padding: 0;
    color: $color-web-text-headline;
  }
}

.subtitle {
  &--redesign {
    @include text-18-regular;

    @include breakpoint-medium-up {
      @include text-20-regular;

      max-width: 72rem;
      margin: 0 auto;
      padding: 0 $spacing-forty;
    }

    @include breakpoint-large-up {
      max-width: 80rem;
      padding: 0;
    }
  }
}

.special-offer {
  margin-bottom: 2rem;
  padding: 0 2rem;

  &--redesign {
    margin-bottom: 0;
    padding: 0 $spacing-sixteen;
  }

  @include breakpoint-medium-up {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.9rem;

    &--redesign {
      flex-wrap: nowrap;
      max-width: 117rem;
      margin: 0 auto;
      align-items: end;
    }
  }

  @include breakpoint-large-up {
    margin-bottom: 3rem;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &--redesign {
    display: flex;
    flex-direction: column;

    @include breakpoint-medium-up {
      flex-direction: row;
    }

    @include breakpoint-large-up {
      margin-bottom: 0;
    }
  }

  &__cell {
    @include breakpoint-medium-only {
      width: 50%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &--special {
        width: 100%;

        &--redesign {
          width: 50%;
        }
      }
    }

    @include breakpoint-large-up {
      width: calc(100% / 3);
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &:nth-child(2) {
        order: 0;
      }

      &:nth-child(1n + 3) {
        order: 2;
      }

      &--special {
        order: 1;
      }
    }

    &--redesign {
      @include breakpoint-medium-up {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }

      &:nth-child(2) {
        order: 0;
      }

      &:nth-child(1n + 3) {
        order: 2;
      }
    }

    &--order {
      order: 1;

      &--reset {
        order: 0;

        @include breakpoint-medium-up {
          order: 1;
        }
      }
    }
  }

  &__disclaimer {
    margin-top: 3rem;
    order: 3;
    text-align: center;
    padding: 0 4rem;
  }

  &__disclaimer-title {
    font-size: 1.8rem;
    margin: 0 0 1.2rem;
    font-weight: 600;
  }

  &__disclaimer-desc {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 500;
  }
}

.bottom-content {
  display: flex;
  flex-direction: column;

  @include breakpoint-medium-up {
    flex-direction: column-reverse;
  }
}

.sticker-container {
  display: flex;
  margin: 0 auto;

  &--allOffers {
    position: absolute;

    @include breakpoint-medium-up {
      position: static;
    }
  }

  &.hasOldPrice {
    justify-content: space-between;
    align-items: baseline;
    position: relative;

    @include breakpoint-medium-up {
      padding-right: 0;
      position: static;
    }
  }

  @include breakpoint-medium-up {
    display: block;
  }
}

.old-price-container {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: left;

  @include breakpoint-medium-up {
    margin-left: $spacing-sixteen;
  }
}

.old-price {
  @include text-16-regular;

  text-decoration: line-through;

  &--highlighted {
    color: $color-core-white;
  }

  @include breakpoint-medium-up {
    &::before {
      width: 10rem;
      margin: 0 auto;
    }
  }
}

.membership-content {
  display: flex;
  flex-direction: column;

  &--special {
    @include breakpoint-medium-up {
      margin-top: 0;
    }
  }
}

.membership {
  display: block;
  border-radius: 1.5rem;
  background-color: #d8d3ef;
  color: $color-text-primary;
  margin-bottom: 1.5rem;

  &--redesign {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    background-color: $color-core-white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    min-height: 8rem;
    border-radius: 2rem;

    &--highlighted {
      background-color: $color-core-primary-light-4;
      box-shadow: none;
    }

    @include breakpoint-medium-up {
      border-radius: 3rem;
    }
  }

  &:hover {
    color: currentColor;
    text-decoration: none;
  }

  &:focus {
    color: currentColor;
    text-decoration: none;
  }

  &--special {
    background-color: $color-primary;
    color: white;

    &:hover,
    &:focus {
      color: white;
    }

    &--redesign {
      color: $color-core-black;
      background-color: $color-core-white;

      &:hover,
      &:focus {
        color: $color-core-black;
      }

      &--allOffers {
        border: none;
        margin-bottom: 2.4rem;
      }

      &--highlighted {
        background-color: $color-core-primary;
        color: $color-core-white;

        &:hover,
        &:focus {
          color: $color-core-white;
        }
      }
    }

    @include breakpoint-medium-only {
      margin-bottom: 3rem;

      &--redesign {
        margin-bottom: $spacing-sixteen;
      }
    }
  }

  &__price {
    @include responsive-title-3;

    @include breakpoint-medium-up {
      display: block;
    }

    &--highlighted {
      color: $color-core-white;
    }
  }

  &__unit {
    &--mobile {
      @include text-16-regular;

      &--highlighted {
        color: $color-core-white;
      }

      @include breakpoint-medium-up {
        display: none;
      }
    }

    &--desktop {
      @include text-16-regular;

      display: none;

      &--highlighted {
        color: $color-core-white;
      }

      @include breakpoint-medium-up {
        display: block;
        text-align: left;
      }
    }
  }

  &__heading {
    @include font-semi-bold();

    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem 2rem;
    margin: 0;

    @include breakpoint-medium-up {
      font-size: 1.8rem;
      line-height: 2.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &--redesign {
      @include responsive-title-6;

      padding: $spacing-four $spacing-twentyfour;
      background-color: $color-illustration-small-background;
      color: $color-web-campaign-message-content;
      border-radius: 0 0 0.8rem 0.8rem;
      box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
      line-height: 2.1rem;

      &--allOffers {
        position: relative;
        top: -1.6rem;
        font-size: 1.4rem;

        @include breakpoint-medium-up {
          font-size: 2rem;
          position: static;
          border: 0.3rem white solid;
        }
      }

      @include breakpoint-medium-up {
        margin: 0;
        padding: $spacing-eight $spacing-twentyfour;
        font-size: 1.8rem;
        line-height: 3rem;
      }

      @include breakpoint-large-up {
        font-size: 2rem;
      }
    }
  }

  &__row {
    &--special {
      @include breakpoint-medium-only {
        display: flex;
      }

      &--redesign {
        @include breakpoint-medium-up {
          display: block;
          margin: 0;
        }
      }
    }
  }

  &__col {
    &--redesign {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: $spacing-sixteen $spacing-sixteen $spacing-twentyfour;

      @include breakpoint-medium-up {
        display: block;
        margin-top: $spacing-twentyfour;
        padding: $spacing-sixteen;

        &--sticker {
          margin-top: 0;
        }
      }

      @include breakpoint-large-up {
        padding: $spacing-sixteen $spacing-thirtytwo;
      }
    }

    &--special {
      @include breakpoint-medium-only {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 0;
        width: 50%;
      }

      &--redesign {
        @include breakpoint-medium-up {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &__timespan {
    @include font-semi-bold();

    font-size: 3rem;
    line-height: 4.2rem;
    padding: 1.5rem 2rem 0;
    margin-bottom: 0.5rem;
    text-align: center;

    &--redesign {
      @include responsive-title-6;

      padding: 0;
      display: flex;
      margin-bottom: $spacing-eight;

      @include breakpoint-medium-up {
        margin-bottom: $spacing-eight;
        align-items: center;
      }
    }

    &--special {
      @include breakpoint-medium-only {
        text-align: left;
      }

      &--redesign {
        &--allOffers {
          margin-bottom: 3.2rem;

          @include breakpoint-medium-up {
            margin-bottom: 0.2rem;
            margin-top: 0.4rem;
          }
        }

        @include breakpoint-medium-up {
          text-align: center;
        }
      }
    }
  }

  &__number {
    @include breakpoint-large-up {
      display: block;
      font-size: 8rem;
      line-height: 8rem;
    }

    &--redesign {
      @include responsive-title-6;
    }
  }

  &__months {
    @include breakpoint-large-up {
      display: block;
      font-size: 2.8rem;
      line-height: 4rem;
    }

    &--redesign {
      @include responsive-title-6;
    }
  }

  &__payment {
    @include font-semi-bold();

    font-size: 3rem;
    line-height: 4.2rem;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
    color: $color-primary;
    text-align: center;

    @include breakpoint-large-up {
      font-size: 2.8rem;
      line-height: 4rem;

      /* NB! Tweak for making campaign with long text fit one row */
      &--special {
        font-size: 2.7rem;
      }
    }

    &--redesign {
      @include text-16-semi-bold;

      margin-bottom: 0;
      background: $color-button-secondary-background-default;
      padding: 1.2rem 1.6rem;
      border-radius: 1.2rem;
      color: $color-button-secondary-contents-default;

      &--highlighted {
        background-color: $color-core-white;
        color: $color-button-primary-outline-contents-default;
      }

      @include breakpoint-medium-up {
        display: none;
      }
    }

    &--special {
      color: $color-core-white;

      @include breakpoint-medium-only {
        text-align: left;
      }

      &--redesign {
        background: $color-button-primary-background-default;
        color: $color-button-primary-contents-default;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.25);

        &--highlighted {
          background-color: $color-core-white;
          color: $color-button-primary-outline-contents-default;
          box-shadow: none;
        }

        &.noSticker {
          position: unset;
        }

        @include breakpoint-medium-up {
          text-align: center;
          position: unset;
          color: $color-core-black;
        }
      }
    }
  }

  &__price-disclaimer {
    font-size: $spacing-twentyfour;
    font-weight: 500;
    line-height: 1.3;
  }

  &__button-container {
    padding: 0 2rem 2rem;

    @include breakpoint-large-up {
      padding-bottom: 2.5rem;
    }

    &--special {
      @include breakpoint-medium-only {
        padding-bottom: 0;
      }
    }

    &--redesign {
      display: none;

      @include breakpoint-medium-up {
        display: block;
        padding: 0 $spacing-sixteen $spacing-thirtytwo;
      }

      @include breakpoint-large-up {
        display: block;
        padding: 0 $spacing-thirtytwo $spacing-thirtytwo;
      }
    }
  }

  &__button {
    font-weight: 600;
    text-align: center;
    transition:
      background-color 80ms ease-out,
      border-color 80ms ease-out,
      color 80ms ease-out;
    background-color: #fff;
    color: $color-primary;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1.1rem 3.9rem;
    display: block;
    width: 100%;
    border-radius: 1rem;
    border: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $color-button-secondary-background-hovered;
      text-decoration: none;
      border: 0;
    }

    @include breakpoint-medium-up {
      font-size: 2rem;
      line-height: 2.6rem;
      padding: 2.1rem 1.9rem;
    }

    &--redesign {
      @include breakpoint-medium-up {
        color: $color-button-secondary-contents-default;
        background-color: $color-button-secondary-background-default;
        font-size: 1.6rem;
        padding: 1.1rem;
      }

      @include breakpoint-large-up {
        @include text-20-semi-bold;

        padding: 1.7rem;
        border-radius: 1.6rem;
      }

      &--special {
        @include breakpoint-medium-up {
          color: $color-button-primary-contents-default;
          background-color: $color-button-primary-background-default;
          box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.25);
          font-size: 1.6rem;
          padding: 1.1rem;
        }

        @include breakpoint-large-up {
          @include text-20-semi-bold;

          padding: 1.7rem;
          border-radius: 1.6rem;
        }
      }

      &--highlighted {
        background-color: $color-core-white;
        color: $color-button-primary-outline-contents-default;
        box-shadow: none;
      }
    }
  }
}

.additional-offer {
  @include font-semi-bold;

  line-height: 2.4rem;
  text-align: center;
  color: white;
  position: relative;
  background-size: cover;

  &--redesign {
    display: flex;
    top: -1rem;
    left: $spacing-sixteen;
    background: none !important;
    font-size: 1.6rem;

    @include breakpoint-medium-up {
      top: -1.3rem;
      left: 0;
      justify-content: center;
      position: relative;
    }
  }
}

.additional-offer-overlay {
  padding: 2rem 2rem 3rem;
  border-radius: 0 0 0.8rem 0.8rem;
  font-size: 2.4rem;
  line-height: 1.25em;

  @include breakpoint-medium-only {
    font-size: 2rem;
  }

  &--redesign {
    background: $color-web-campaign-focus-badge;
    color: $color-web-campaign-focus-content;
    border-radius: 0.4rem;
    padding: 0.2rem $spacing-eight;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include breakpoint-medium-up {
      @include font-semi-bold;

      border-radius: 0.8rem;
      padding: $spacing-eight $spacing-twentyfour;
      position: absolute;
    }

    @include breakpoint-large-up {
      font-size: 2rem;
      line-height: 3rem;

      &--allOffers {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

.footnote {
  margin: 0 auto $spacing-sixteen;
  padding: 0 $spacing-sixteen;
  font-size: 1.4rem;
  line-height: 2rem;
  max-width: 80rem;
  text-align: center;

  @include breakpoint-medium-up {
    margin: $spacing-eight auto 0;
    padding: 0 $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    margin-top: $spacing-twentyfour;
  }
}

.paymentHeadline {
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: $spacing-eight;

  @include breakpoint-medium-up {
    padding-bottom: $spacing-twentyfour;
    font-size: 2rem;
  }

  svg {
    overflow: hidden;
    width: auto;
    height: 2rem;
    margin: 0.7rem 0 0 0.5rem;
  }

  &--redesign {
    padding-bottom: 0;
    margin: 0;
  }
}

.logos {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 2rem 0 1.2rem;

  &--redesign {
    margin: $spacing-sixteen 0 $spacing-twentyfour;

    @include breakpoint-medium-up {
      margin: $spacing-thirtytwo 0 $spacing-sixtyfour;
    }
  }
}

.icon {
  height: 2.9rem;
  margin: 0 0.5rem;

  @include breakpoint-medium-up {
    height: 3.4rem;
    margin: 0 $spacing-eight;
  }

  img,
  svg {
    display: block;
    height: 100%;
    width: auto;
  }
}

.pricesContainer {
  @include font-regular;

  color: $color-text-primary;
  font-size: 2rem;
  line-height: 3rem;
  padding: 0;
  margin: 0;

  &::before {
    @include underline;

    content: "";
    display: block;
    margin-bottom: $spacing-eight;

    @include breakpoint-medium-up {
      margin-bottom: $spacing-sixteen;
    }
  }

  &--highlighted {
    &::before {
      background-color: $color-core-white;
    }
  }

  @include breakpoint-medium-up {
    flex-direction: column;
  }
}

.offerPrice {
  text-align: left;

  @include breakpoint-medium-up {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.offerPricesContainer {
  display: flex;
  flex-direction: column;

  @include breakpoint-medium-up {
    flex-direction: row;
    align-items: baseline;
  }
}
