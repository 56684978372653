@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/transitions";

@mixin image-placeholder(
  $small-ratio: 100%,
  $medium-ratio: $small-ratio,
  $large-ratio: $small-ratio
) {
  position: relative;
  display: block;
  padding-bottom: $small-ratio;
  height: 0;
  margin: 0;
  overflow: hidden;

  @include breakpoint-small-only {
    padding-bottom: $small-ratio;
  }

  @include breakpoint-medium-only {
    padding-bottom: $medium-ratio;
  }

  @include breakpoint-large-up {
    padding-bottom: $large-ratio;
  }
}

@mixin image-in-placeholder() {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0;
  top: 0;
  border: none;
  overflow: hidden;
  opacity: 0;
  transition: opacity $transition-duration-fast ease-out;

  &.lazyloaded {
    opacity: 1;
  }
}
