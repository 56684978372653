@import "styles/_theme-wellobe.scss";
.adDisclaimer {
  text-align: center;
  line-height: 2;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adTitle {
  flex-grow: 0;
  color: inherit;
  font-size: 1.2rem;
}

.adLine {
  border: 1px solid;
  border-color: inherit;
  display: flex;
  flex-grow: 2;
  height: 0.1rem;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 0 1rem;
}
