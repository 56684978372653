@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

.container {
  padding: 0 2rem;

  @include breakpoint-medium-up {
    padding: 0 2.5rem;
  }

  @include breakpoint-large-up {
    padding: 0;
    max-width: 79.2rem;
    clear: both;
  }
}

.box {
  margin: 0 auto;
  box-shadow: 0 0.2rem 0.4rem 0.1rem rgba(0, 0, 0, 0.17);
  border-radius: 0.8rem;

  .vev .__sc {
    grid-template-columns: 1fr 100% 1fr !important;
  }
}
