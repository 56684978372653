@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/panels";
@import "~@styles/utils/mixins/buttons";
@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/mixins/breakpoints";

.container {
  background-color: white;
  margin-bottom: 3rem;
  border-radius: 1rem;
}

.header {
  @include panel-padding;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 0.1rem $color-web-divider-secondary;
  display: flex;
}

.heading {
  @include font-semi-bold;

  font-size: 2rem;
  line-height: 1.5;
  margin: 0;
  flex-grow: 1;
  color: $color-text-primary;
}

.item-button {
  @include button-reset;
  @include font-regular;
  @include truncate;

  width: 100%;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-top: 0.8rem;
  text-decoration: none;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }

  &__active {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
  }
}

.description {
  display: inline-flex;
  font-size: 1.6rem;
}

.content {
  @include panel-padding;
}

.verticalDescription {
  flex-direction: column;
  padding: 1rem 0;

  @include breakpoint-medium-only {
    padding: 2rem 0;
    flex-direction: row;
  }
}
