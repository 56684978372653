@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  margin: 5rem auto;
  padding: 0 2rem;
  border-top: 1px solid transparent;
  max-width: 70rem;
}

.iconWrapper {
  position: relative;
  margin-bottom: 1rem;
}

.icon {
  width: 6rem;
  height: 6rem;
  fill: $color-action-icon-secondary;
}

.title {
  font-weight: 700;
  color: inherit;
  font-size: 2.6rem;
  line-height: 3.4rem;
  margin: 0 0 0.5rem;

  @include breakpoint-medium-up {
    font-size: 3.6rem;
    line-height: 4.5rem;
  }
}

.description {
  color: inherit;
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0 0 1.5rem;

  @include breakpoint-medium-up {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }
}

.cta {
  margin: 3rem 0;
}
