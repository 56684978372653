@import "~@styles/utils/mixins/breakpoints";

@mixin panel-padding {
  padding: 2rem;

  @include breakpoint-small-down {
    padding: 1rem;
  }

  @include breakpoint-medium-only {
    padding: 1.5rem;
  }
}
