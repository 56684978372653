@import "~@styles/utils/variables/breakpoints";

// Up
@mixin breakpoint-smallest-up {
  @media (min-width: #{$breakpoint-smallest}) {
    @content;
  }
}

@mixin breakpoint-smaller-up {
  @media (min-width: #{$breakpoint-smaller}) {
    @content;
  }
}

@mixin breakpoint-small-up {
  @media (min-width: #{$breakpoint-small}) {
    @content;
  }
}

@mixin breakpoint-medium-up {
  @media (min-width: #{$breakpoint-medium}) {
    @content;
  }
}

@mixin breakpoint-large-up {
  @media (min-width: #{$breakpoint-large}) {
    @content;
  }
}

@mixin breakpoint-larger-up {
  @media (min-width: #{$breakpoint-larger}) {
    @content;
  }
}

// Down
@mixin breakpoint-smallest-down {
  @media (max-width: #{$breakpoint-smaller - 1px}) {
    @content;
  }
}

@mixin breakpoint-smaller-down {
  @media (max-width: #{$breakpoint-small - 1px}) {
    @content;
  }
}

@mixin breakpoint-small-down {
  @media (max-width: #{$breakpoint-medium - 1px}) {
    @content;
  }
}

@mixin breakpoint-medium-down {
  @media (max-width: #{$breakpoint-large - 1px}) {
    @content;
  }
}

@mixin breakpoint-large-down {
  @media (max-width: #{$breakpoint-larger - 1px}) {
    @content;
  }
}

// Only
@mixin breakpoint-smallest-only {
  @media (min-width: #{$breakpoint-smallest}) and (max-width: #{$breakpoint-smaller - 1px}) {
    @content;
  }
}

@mixin breakpoint-smaller-only {
  @media (min-width: #{$breakpoint-smaller}) and (max-width: #{$breakpoint-small - 1px}) {
    @content;
  }
}

@mixin breakpoint-small-only {
  @media (min-width: #{$breakpoint-small}) and (max-width: #{$breakpoint-medium - 1px}) {
    @content;
  }
}

@mixin breakpoint-medium-only {
  @media (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large - 1px}) {
    @content;
  }
}

@mixin breakpoint-large-only {
  @media (min-width: #{$breakpoint-large}) and (max-width: #{$breakpoint-larger - 1px}) {
    @content;
  }
}

@mixin breakpoint-larger-only {
  @media (min-width: #{$breakpoint-larger}) {
    @content;
  }
}
