@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/underline";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

@mixin container-title {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }
}

.articles-container {
  max-width: 128rem;
  margin: $spacing-forty auto 0;
  padding: 0 $spacing-sixteen $spacing-fortyeight;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix $spacing-eighty;
  }
}

.rest-of-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-twentyfour;

    & > article {
      margin-bottom: $spacing-eight;
    }
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;

    & > article {
      margin-bottom: $spacing-thirtytwo;
    }
  }
}

.no-query-container {
  @include container-title;

  margin-top: $spacing-forty;
}

.more-articles-title-container {
  @include container-title;

  margin-top: $spacing-forty;
}

.more-articles-title {
  @include responsive-title-4;

  margin-bottom: $spacing-sixteen;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-eight;
  }
}

.more-articles-count {
  margin-bottom: $spacing-thirtytwo;
}

.underline {
  @include underline;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;
  text-align: center;
  padding: $spacing-twentyfour $spacing-sixteen;
  background-image: url("./assets/#{$product}/background.avif");
  background-size: cover;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding: $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixtyfour;
  }

  .input {
    margin: 0 auto;
    width: 100%;

    & > div {
      margin: 0;
    }

    @include breakpoint-medium-up {
      width: 600px;
    }
  }
}

.header {
  @include responsive-title-1;

  color: $color-web-text-headline;
}

.header-description {
  @include text-16-regular;

  @include breakpoint-medium-up {
    @include text-20-regular;

    max-width: 72rem;
    margin: 0 auto;
    padding: 0 $spacing-forty;
  }

  @include breakpoint-large-up {
    max-width: 80rem;
    padding: 0;
  }
}

.no-articles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-eight;
}

.no-articles-header {
  @include title-20;

  margin-top: $spacing-sixteen;
}
