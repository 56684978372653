@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";

.health-benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.9rem;
  text-align: center;
  max-width: 42rem;
  border-radius: 1.5rem;
  margin: 0 auto $spacing-twentyfour;
  color: $color-text-primary;

  @include breakpoint-medium-up {
    max-width: unset;
    padding: 0;
    background-position: 2.5rem 2.9rem;
    margin-bottom: 0;
    text-align: left;
    font-size: 2rem;
    line-height: 3rem;
  }

  @include breakpoint-large-up {
    margin-top: 0.8rem;
  }
}

.icon {
  :nth-child(1) {
    fill: $color-illustration-large-background;
  }

  :nth-child(2) {
    fill: $color-illustration-large-contents;
  }

  @include breakpoint-medium-up {
    width: 4rem;
    height: 4rem;
  }
}

.link {
  color: currentColor;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
