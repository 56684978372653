@import "~@styles/utils/mixins/breakpoints";

@mixin font-regular {
  font-weight: 400;
}

@mixin font-medium {
  font-weight: 500;
}

@mixin font-semi-bold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 700;
}

@mixin font-regular-italic {
  @include font-regular;

  font-style: italic;
}

@mixin h1 {
  @include font-bold;

  font-size: 2.4rem;
  line-height: 1.3;
  margin: 0 auto 1.6rem;

  @include breakpoint-medium-up {
    font-size: 3.6rem;
    margin-bottom: 2.4rem;
  }

  @include breakpoint-large-up {
    font-size: 4.2rem;
  }
}

@mixin h2 {
  @include font-semi-bold;

  font-size: 2rem;
  line-height: 1.4;
  margin: 0 auto 0.8rem;

  @include breakpoint-medium-up {
    @include font-bold;

    font-size: 2.4rem;
  }
}

@mixin h3 {
  @include font-semi-bold;

  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 0 1em;
}

@mixin h4 {
  @include font-semi-bold;

  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0 0 1em;
}

@mixin h5 {
  @include font-semi-bold;

  font-size: 1.8rem;
  margin: 0 0 1em;
  line-height: 1.4;
}

@mixin p {
  font-size: 1.6rem;
  margin: 0 0 1em;
}

@mixin a {
  color: $color-web-link-default;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    text-decoration: underline;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-25 {
  font-size: 2.5rem;
  line-height: 1.5;
  margin: 0;
}

@mixin text-20 {
  font-size: 2rem;
  line-height: 1.5;
  margin: 0;
}

@mixin text-18 {
  font-size: 1.8rem;
  line-height: 1.4;
  margin: 0;
}

@mixin text-16 {
  font-size: 1.6rem;
  line-height: 1.4;
  margin: 0;
}

@mixin text-14 {
  font-size: 1.4rem;
  line-height: 1.4;
  margin: 0;
}

@mixin text-13 {
  font-size: 1.3rem;
  line-height: 1.4;
  margin: 0;
}

@mixin text-12 {
  font-size: 1.2rem;
  line-height: 1.4;
  margin: 0;
}

@mixin text-25-regular {
  @include text-25;
  @include font-regular;
}

@mixin text-20-regular {
  @include text-20;
  @include font-regular;
}

@mixin text-20-semi-bold {
  @include text-20;
  @include font-semi-bold;
}

@mixin text-18-regular {
  @include text-18;
  @include font-regular;
}

@mixin text-16-regular {
  @include text-16;
  @include font-regular;
}

@mixin text-16-medium {
  @include text-16;
  @include font-medium;
}

@mixin text-16-semi-bold {
  @include text-16;
  @include font-semi-bold;
}

@mixin text-14-regular {
  @include text-14;
  @include font-regular;
}

@mixin text-14-medium {
  @include text-14;
  @include font-medium;
}

@mixin text-14-semi-bold {
  @include text-14;
  @include font-semi-bold;
}

@mixin text-14-semi-bold-caps {
  @include text-14-semi-bold;

  text-transform: uppercase;
}

@mixin text-13-medium {
  @include text-13;
  @include font-medium;
}

@mixin text-13-medium-caps {
  @include text-13-medium;

  text-transform: uppercase;
}

@mixin text-12-regular {
  @include text-12;
  @include font-regular;
}

@mixin text-12-medium {
  @include text-12;
  @include font-medium;
}

@mixin text-12-semi-bold {
  @include text-12;
  @include font-semi-bold;
}

@mixin text-12-semi-bold-caps {
  @include text-12-medium;
  @include font-semi-bold;

  text-transform: uppercase;
}

@mixin title {
  @include font-bold;

  margin: 0;
}

@mixin title-48 {
  @include title;

  font-size: 4.8rem;
  line-height: 1.1;
}

@mixin title-40 {
  @include title;

  font-size: 4rem;
  line-height: 1.1;
}

@mixin title-35 {
  @include title;

  font-size: 3.5rem;
  line-height: 1.2;
}

@mixin title-30 {
  @include title;

  font-size: 3rem;
  line-height: 1.3;
}

@mixin title-25 {
  @include title;

  font-size: 2.5rem;
  line-height: 1.3;
}

@mixin title-20 {
  @include title;

  font-size: 2rem;
  line-height: 1.3;
}

@mixin title-18 {
  @include title;

  font-size: 1.8rem;
  line-height: 1.3;
}

@mixin title-16 {
  @include title;

  font-size: 1.6rem;
  line-height: 1.2;
}

@mixin responsive-title-1 {
  @include title-25;

  @include breakpoint-medium-up {
    @include title-40;
  }

  @include breakpoint-large-up {
    @include title-48;
  }
}

@mixin responsive-title-2 {
  @include title-25;

  @include breakpoint-medium-up {
    @include title-35;
  }

  @include breakpoint-large-up {
    @include title-40;
  }
}

@mixin responsive-title-3 {
  @include title-25;

  @include breakpoint-medium-up {
    @include title-30;
  }

  @include breakpoint-large-up {
    @include title-35;
  }
}

@mixin responsive-title-4 {
  @include title-20;

  @include breakpoint-medium-up {
    @include title-25;
  }

  @include breakpoint-large-up {
    @include title-30;
  }
}

@mixin responsive-title-5 {
  @include title-20;

  @include breakpoint-medium-up {
    @include title-20;
  }

  @include breakpoint-large-up {
    @include title-25;
  }
}

@mixin responsive-title-6 {
  @include title-18;

  @include breakpoint-medium-up {
    @include title-18;
  }

  @include breakpoint-large-up {
    @include title-20;
  }
}
