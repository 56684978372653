@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.f1 {
  @include breakpoint-medium-up {
    padding-bottom: 5rem;
  }
}

.featuresWrapper {
  margin: $spacing-fortyeight 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    margin: $spacing-sixtyfour 0;
  }

  @include breakpoint-large-up {
    margin: $spacing-eighty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: $spacing-eighty auto;
  }
}

.how-it-works-button {
  @include text-16-semi-bold;

  align-self: center;
  padding: $spacing-twelve $spacing-twentyfour;
  margin: 0;

  @include breakpoint-medium-up {
    @include text-20-semi-bold;

    padding: $spacing-sixteen $spacing-fortyeight;
  }

  @include breakpoint-large-up {
    border-radius: 1.6rem;
  }
}

.backgroundWrapper {
  background-color: $color-core-grey-light-5;
}

.additionalInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: $spacing-sixteen;
  padding: $spacing-sixteen;

  @include breakpoint-medium-up {
    gap: $spacing-thirtytwo;
    padding: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: $spacing-twentyfour $spacing-fiftysix;
    justify-content: start;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: 0 auto;
    padding: $spacing-twentyfour 0;
  }
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  gap: $spacing-four;
  align-items: center;

  @include breakpoint-large-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    flex-direction: row;
  }
}

.additionalInfoMultiple {
  @include breakpoint-large-up {
    width: 50%;
  }
}

.additionalInfoText {
  @include text-16-regular;

  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include breakpoint-medium-up {
    text-align: start;
  }
}

.additionalInfoTextBold {
  @include text-16-semi-bold;

  display: block;

  @include breakpoint-medium-up {
    display: inline;
  }
}

.icon {
  height: 4rem;
  width: 4rem;
}

.heartCheckIcon {
  height: 4rem;
  width: 4rem;

  :nth-child(1) {
    fill: $color-illustration-large-background;
  }

  :nth-child(2) {
    fill: $color-illustration-large-contents;
  }
}
