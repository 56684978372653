@import "styles/_theme-wellobe.scss";
@import "https://fonts.googleapis.com/css?family=Poppins:400,400i,500,600,700&display=swap";
@import "https://unpkg.com/@schibsted/data-controller/dist/styles.css";
@import "@styles/base/scaffolding.scss";
@import "@styles/base/braze.scss";
// Include print styles last for the cascade to work
@import "@styles/base/print.scss";

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    width: 100%;
  }
}

.scrollToTop {
  min-height: calc(100vh - 6rem);
}
